import { useMemo, useRef, useState } from 'react';
import SwiperCore, { SwiperOptions } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { KProductCardType } from '~/lib/data-contract';
import { Gutter, MaxWidth } from '~/shared/components';
import { CarouselButton } from '~/shared/components/Carousel/components/CarouselButton/CarouselButton';
import { KProductCard } from '~/shared/components/KProductCard';
import { useSwiperHelper } from '~/shared/hooks/useSwiperHelper/useSwiperHelper';
import { useFrame, useTranslation } from '~/shared/utils';
import { mapProductToBasicProps } from '~/templates/blocks/components/M140ProductsList/components/utils/mapProductToBasicProps';
import { getCurrentMarketPath } from '~/templates/pages/utils';
import { Product } from '../../../../../templates/blocks/components/M140ProductsList';
import {
    StyledCarouselButtonContainer,
    StyledProductCarouselWrapper,
    StyledRelativeContainer,
} from './styled';

type ProductCarouselProps = {
    products: Product[];
};

export const ProductCarousel = ({ products = [] }: ProductCarouselProps) => {
    const moduleWidthRef = useRef<HTMLDivElement>(null);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore | undefined>();
    const productPageUrl = `/${getCurrentMarketPath()}/p`;
    const { hasNext, hasPrev, slideNext, slidePrev } = useSwiperHelper(swiperInstance);
    const { data: frame } = useFrame();
    const { translate } = useTranslation();

    const productsAsProps = useMemo(() => {
        return products.map((item) =>
            mapProductToBasicProps({ product: item, productPageUrl, frame, translate }),
        );
    }, [products, productPageUrl, frame, translate]);

    return (
        <StyledProductCarouselWrapper ref={moduleWidthRef}>
            <MaxWidth>
                <Gutter>
                    <StyledRelativeContainer>
                        {hasPrev && (
                            <StyledCarouselButtonContainer alignment="left">
                                <CarouselButton direction="left" onClick={slidePrev} />
                            </StyledCarouselButtonContainer>
                        )}
                        {hasNext && (
                            <StyledCarouselButtonContainer alignment="right">
                                <CarouselButton direction="right" onClick={slideNext} />
                            </StyledCarouselButtonContainer>
                        )}
                        <Swiper
                            role="list"
                            {...swiperConfig}
                            onSwiper={(instance) => {
                                setSwiperInstance(instance);
                            }}
                        >
                            {productsAsProps.map((product) => {
                                return (
                                    <SwiperSlide key={`${product.productId ?? product.title}`}>
                                        <KProductCard
                                            type={KProductCardType.REGULAR}
                                            {...product}
                                            className="productCard"
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </StyledRelativeContainer>
                </Gutter>
            </MaxWidth>
        </StyledProductCarouselWrapper>
    );
};

const swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    slideToClickedSlide: false,
    mousewheel: {
        forceToAxis: true, // Enables horizontal scroll, but prevents carousel from hijacking regular vertical scroll
    },
    speed: 500,
    spaceBetween: 30,
    autoplay: false,
    a11y: {
        enabled: false, // Fixes jumps when clicking slide outside natural viewport
    },
    breakpoints: {
        540: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
        },
        1280: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 30,
        },
    },
};
